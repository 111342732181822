<template>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
  <b-form
    class="bg-white p-2"
    @submit.prevent="handleSubmit(onSubmit)"
  >

    <h3 class="mb-2">
      Dane osobowe
    </h3>
    <b-row>
      <b-col cols="12">
        <validation-provider
          #default="validationContext"
          name="Imię i nazwisko"
          v-model="userData.name"
          rules="required"
        >
        <b-form-group
          label="Imię i nazwisko"
          label-for="h-first-name"
          label-cols-md="4"
        >
          <b-form-input
              id="username"
              v-model="userData.name"
              :state="getValidationState(validationContext)"
              trim
            placeholder="Imię i nazwisko"
          />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col cols="12">
        <validation-provider
          #default="validationContext"
          name="Email"
          rules="required|email"
        >
        <b-form-group
          label="Adres email"
          label-for=""
          label-cols-md="4"
        >
          <b-form-input
              id="email"
              v-model="userData.email"
              :state="getValidationState(validationContext)"
              trim
            placeholder="Adres email"
          />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
        </b-form-group>
        </validation-provider>
      </b-col>

      <b-col cols="12">
        <!-- Password -->
        <validation-provider
          #default="validationContext"
          name="Hasło"
          rules="required|min:8"
        >
          <b-form-group
            label="Hasło"
            label-for="password"
            label-cols-md="4"
          >
            <b-form-input
              id="password"
              v-model="userData.password"
              type="password"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col cols="12">
        <validation-provider
          #default="validationContext"
          name="Numer telefonu"
          rules="required|digits:9"
        >
	        <b-form-group
	          label="Numer telefonu"
	          label-for=""
	          label-cols-md="4"
	        >
	          <b-form-input
              	id="phone-number"
                v-model="userData.phone"
	            placeholder="Numer telefonu"
                :state="getValidationState(validationContext)"
                trim
	          />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
	        </b-form-group>
        </validation-provider>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Nazwisko rodowe"
          label-for=""
          label-cols-md="4"
        >
          <b-form-input
            id=""
            placeholder="Nazwisko rodowe"
            v-model="userData.surname"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Imiona rodziców"
          label-for="parents-names"
          label-cols-md="4"
        >
          <b-form-input
            id="parents-names"
            placeholder="Imiona rodziców"
            v-model="userData.parrents_name"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="PESEL"
          label-for="pesel"
          label-cols-md="4"
        >
          <b-form-input
            id="pesel"
            placeholder="PESEL"
            v-model="userData.PESEL"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="NIP"
          label-for="nip"
          label-cols-md="4"
        >
          <b-form-input
            id="nip"
            placeholder="NIP"
            v-model="userData.NIP"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Data urodzenia"
          label-for="birthdate"
          label-cols-md="4"
        >
          <b-form-input
            id="birthdate"
            type="date"
            placeholder="Data urodzenia"
            v-model="userData.birth_date"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Miejsce urodzenia"
          label-for=""
          label-cols-md="4"
        >
          <b-form-input
            id=""
            placeholder="Miejsce urodzenia"
            v-model="userData.birth_address"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Płeć"
          label-for=""
          label-cols-md="4"
        >
          <b-form-input
            id=""
            placeholder="Płeć"
            v-model="userData.gender"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Dowód osobisty"
          label-for=""
          label-cols-md="4"
        >
          <b-form-input
            id=""
            placeholder="Dowód osobisty"
            v-model="userData.id_card"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Obywatelstwo"
          label-for=""
          label-cols-md="4"
        >
          <b-form-input
            id=""
            placeholder="Obywatelstwo"
            v-model="userData.citizenship"
          />
        </b-form-group>
      </b-col>

    </b-row>

    <div class="d-flex align-items-center mb-3 mt-2">
      <h3 class="mb-0 pb-0 mr-2">
        Adres zameldowania
      </h3>
      <p class="mb-0 pb-0">
        ( Pozostaw puste, jeśli brak adresu zameldowania )
      </p>
    </div>

        <b-row>

    <b-col cols="12">
      <b-form-group
        label="Ulica, Numer"
        label-for="h-first-name"
        label-cols-md="4"
      >
        <b-form-input
          id="h-first-name"
          placeholder="Ulica, Numer"
          v-model="userData.address_street"
        />
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-form-group
        label="Kod pocztowy, Miejscowość"
        label-for="h-first-name"
        label-cols-md="4"
      >
        <b-form-input
          id="h-first-name"
          placeholder="Kod pocztowy, Miejscowość"
          v-model="userData.address_zip_code"
        />
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-form-group
        label="Poczta"
        label-for=""
        label-cols-md="4"
      >
        <b-form-input
          id=""
          placeholder="Poczta"
          v-model="userData.address_post_office"
        />
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-form-group
        label="Gmina"
        label-for=""
        label-cols-md="4"
      >
        <b-form-input
          id=""
          placeholder="Gmina"
          v-model="userData.address_community"
        />
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-form-group
        label="Powiat"
        label-for=""
        label-cols-md="4"
      >
        <b-form-input
          id=""
          placeholder="Powiat"
          v-model="userData.address_powiat"
        />
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-form-group
        label="Województwo"
        label-for=""
        label-cols-md="4"
      >
        <b-form-input
          id=""
          placeholder="Województwo"
          v-model="userData.address_voivodeship"
        />
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-form-group
        label="Kraj"
        label-for=""
        label-cols-md="4"
      >
        <b-form-input
          id=""
          placeholder="Kraj"
          v-model="userData.address_country"
        />
      </b-form-group>
    </b-col>

  </b-row>

    <h3 class="mb-2 mt-3">
      Adres zamieszkania (wykazywany na deklaracji PIT-11)
    </h3>
    <b-form-checkbox
      v-model="selected"
      value="B"
      class="mb-2"
    >
      Taki sam jak adres zameldowania
    </b-form-checkbox>

    <b-col cols="12">
        <b-form-group
          label="Ulica, Numer"
          label-for="h-first-name"
          label-cols-md="4"
        >
          <b-form-input
            id="h-first-name"
            placeholder="Ulica, Numer"
            v-model="userData.address_street"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Kod pocztowy, Miejscowość"
          label-for="h-first-name"
          label-cols-md="4"
        >
          <b-form-input
            id="h-first-name"
            placeholder="Kod pocztowy, Miejscowość"
            v-model="userData.address_zip_code"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Poczta"
          label-for=""
          label-cols-md="4"
        >
          <b-form-input
            id=""
            placeholder="Poczta"
            v-model="userData.address_post_office"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Gmina"
          label-for=""
          label-cols-md="4"
        >
          <b-form-input
            id=""
            placeholder="Gmina"
            v-model="userData.address_community"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Powiat"
          label-for=""
          label-cols-md="4"
        >
          <b-form-input
            id=""
            placeholder="Powiat"
            v-model="userData.address_powiat"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Województwo"
          label-for=""
          label-cols-md="4"
        >
          <b-form-input
            id=""
            placeholder="Województwo"
            v-model="userData.address_voivodeship"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Kraj"
          label-for=""
          label-cols-md="4"
        >
          <b-form-input
            id=""
            placeholder="Kraj"
            v-model="userData.address_country"
          />
        </b-form-group>
      </b-col>

    <h3 class="mb-2 mt-3">
      Adres korespondencyjny
    </h3>
    <b-form-checkbox
      v-model="selected"
      value="B"
      class="mb-2"
    >
      Taki sam jak adres zameldowania
    </b-form-checkbox>

    <b-col cols="12">
      <b-form-group
        label="Ulica, Numer"
        label-for="h-first-name"
        label-cols-md="4"
      >
        <b-form-input
          id="h-first-name"
          placeholder="Ulica, Numer"
          v-model="userData.address_street"
        />
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-form-group
        label="Kod pocztowy, Miejscowość"
        label-for="h-first-name"
        label-cols-md="4"
      >
        <b-form-input
          id="h-first-name"
          placeholder="Kod pocztowy, Miejscowość"
          v-model="userData.address_zip_code"
        />
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-form-group
        label="Poczta"
        label-for=""
        label-cols-md="4"
      >
        <b-form-input
          id=""
          placeholder="Poczta"
          v-model="userData.address_post_office"
        />
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-form-group
        label="Gmina"
        label-for=""
        label-cols-md="4"
      >
        <b-form-input
          id=""
          placeholder="Gmina"
          v-model="userData.address_community"
        />
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-form-group
        label="Powiat"
        label-for=""
        label-cols-md="4"
      >
        <b-form-input
          id=""
          placeholder="Powiat"
          v-model="userData.address_powiat"
        />
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-form-group
        label="Województwo"
        label-for=""
        label-cols-md="4"
      >
        <b-form-input
          id=""
          placeholder="Województwo"
          v-model="userData.address_voivodeship"
        />
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-form-group
        label="Kraj"
        label-for=""
        label-cols-md="4"
      >
        <b-form-input
          id=""
          placeholder="Kraj"
          v-model="userData.address_country"
        />
      </b-form-group>
    </b-col>

    <h3 class="mb-2 mt-3">
      Przynależność do Urzędu Skarbowego
    </h3>
    <b-row>

      <b-col
        cols="12"
        lg="4"
      />

      <!-- status rezydencji -->
      <b-col
        cols="12"
        lg="8"
        class="d-flex align-items-center pb-2"
      >

        <div class="p-0 pr-1">
          Status rezydencji podatkowej
        </div>

        <b-form-checkbox
          v-model="selected"
          value="B"
        >
          <div class="p-0">
            Rezydent
          </div>

        </b-form-checkbox>
      </b-col>
      <!-- status rezydencji -->

      <b-col cols="12">
        <b-form-group
          label="Urząd skarbowy"
          label-for=""
          label-cols-md="4"
        >
          <b-form-input
            id=""
            placeholder="Wybierz urząd skarbowy"
            v-model="userData.tax_office"
          />
        </b-form-group>
      </b-col>

    </b-row>

    <h3 class="mb-2 mt-3">
      Rachunek bankowy
    </h3>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Nazwa Banku"
          label-for=""
          label-cols-md="4"
        >
          <b-form-input
            id=""
            placeholder="Nazwa Banku"
            v-model="userData.bank_name"
          />
        </b-form-group>

        <b-form-group
          label="Numer konta"
          label-for=""
          label-cols-md="4"
        >
          <b-form-input
            id=""
            placeholder="Numer konta"
            v-model="userData.bank_account_number"
          />
        </b-form-group>

      </b-col>

    </b-row>

    <!-- Form Actions -->
    <div class="d-flex mt-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mr-2"
        type="submit"
        @click="submit ()"
      >
        <feather-icon
          icon="PlusSquareIcon"
          size="18"
          class="mr-50"
        />
        Edytuj
      </b-button>
    </div>

  </b-form>
</validation-observer>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormCheckbox, BButton, BFormInvalidFeedback, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import countries from '@/@fake-db/data/other/countries'
import Swal from 'sweetalert2'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BFormSelect,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected: ['A', 'B'],
      required,
      alphaNum,
      email,
      countries,
      userData: {},
    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      name: '',
      email: '',
      password: '',
      tel: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      axios.post(`${process.env.VUE_APP_API_URL}User`, userData.value, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(() => {
        emit('refetch-data')
        emit('update:is-add-new-user-sidebar-active', false)
        Swal.fire(
          'Pomyślnie dodano użytkownika',
          '',
          'success',
        )
      }).catch(error => {
        console.log(error)
        Swal.fire(
          'Coś poszło nie tak!',
          '',
          'error',
        )
      })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  }, created () {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
      try{
        axios.get(`${process.env.VUE_APP_API_URL}employee/${this.$route.params.id}`, config)
          .then(response => {
            this.userData = response.data
          })
      }
      catch(error){
        console.log(error)
        Swal.fire(
          error,
          '',
          'error',
        )
      }
  }, methods: {
    update_worker ( data ) {
      axios.put(`${process.env.VUE_APP_API_URL}employee/${this.$route.params.id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(() => {
        this.$router.push('/apps/workers/list')
        Swal.fire(
          'Pomyślnie zaktualizowano dane pracownika',
          '',
          'success',
        )
      }).catch(error => {
        console.log(error)
        Swal.fire(
          'Coś poszło nie tak!',
          '',
          'error',
        )
      })
    },  
    submit () {
      Swal.fire({
        title: 'Czy jesteś pewien?',
        text: 'Zmiany zostaną zapisane',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Tak, zapisz!',
        cancelButtonText: 'Nie, anuluj!',
      }).then((result) => {
        if (result.value) {
          this.update_worker(this.userData)
        }
      })
    } 
  }
}
</script>

<style>

</style>
