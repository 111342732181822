<template>
  <div class="add-new-worker--wrapper">
    <WorkerInfo />
  </div>

</template>

<script>

import WorkerInfo from './single-worker/tabs/WorkerInfo.vue'

export default {
  components: {
    WorkerInfo,
  },
}
</script>

<style>

</style>
